/** @jsx $jsx */
import { $jsx } from 'framework7';
import i18next from '../../src/i18n.js';
import { LoginScreenPage } from '../../src/components/login-screen-page.ts';
function framework7Component(props, ctx) {
  const skip = i18next.t('login.skip');
  new LoginScreenPage(ctx);
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
   <div class="page no-toolbar no-navbar no-swipeback login-screen-page">
      <div class="page">
         <div id="firebaseui-auth-container"></div>
         <span class="buttons-row"><a href="/search/">${skip}</a></span>
      </div>
   </div>
`
    }
    ;
}
framework7Component.id = '731ebf6070';
export default framework7Component;