import {EmailAuthProvider, getAuth} from 'firebase/auth';
import {ComponentContext} from 'framework7/modules/component/component';
import i18next from 'i18next';

export class LoginScreenPage {
   constructor(ctx: ComponentContext) {
      ctx.$on('pageAfterIn', async () => {
         // FirebaseUI config.
         const uiConfig = {
            callbacks: {
               signInSuccessWithAuthResult: () => {
                  ctx.$f7router.navigate('/search/');
                  return true;
               },
            },
            signInOptions: [
               // Leave the lines as is for the providers you want to post your users.
               // GoogleAuthProvider.PROVIDER_ID,
               // FacebookAuthProvider.PROVIDER_ID,
               EmailAuthProvider.PROVIDER_ID,
            ],
         };

         const firebaseResolver = (language: string) => require(
            (language === 'en') ? 'firebaseui/dist/npm.js' : 'firebaseui/dist/npm__bg.js');
         const firebaseui = firebaseResolver(i18next.language);

         let ui = firebaseui.auth.AuthUI.getInstance();
         if (!ui) {
            ui = new firebaseui.auth.AuthUI(getAuth());
         }
         ui.start('#firebaseui-auth-container', uiConfig);
      });
   }
}
