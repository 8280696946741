import {getApps, initializeApp} from 'firebase/app';
import {Functions, HttpsCallable, getFunctions, httpsCallable} from 'firebase/functions';

export class Page {
   private firebaseConfig = {
      apiKey: 'AIzaSyBGWM7WmPq6I7IBR4kAdLZPLfVAitxbHC0',
      authDomain: 'oopsy-app.firebaseapp.com',
      projectId: 'oopsy-app',
      storageBucket: 'oopsy-app.appspot.com',
      messagingSenderId: '409861724524',
      appId: '1:409861724524:web:e35c358660ff324debfb49',
      measurementId: 'G-5W5PBWZGNN',
   };

   private functions: Functions;

   constructor() {
      const app = (!getApps().length) ? initializeApp(this.firebaseConfig) : getApps()[0];
      this.functions = getFunctions(app, 'europe-central2');
   }

   protected getFunction(name: string): HttpsCallable {
      // connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
      return httpsCallable(this.functions, name,);
   }
}
