import {getAuth, signOut} from 'firebase/auth';
import AboutPage from '../public/pages/about-page.html';
import LoginScreenPage from '../public/pages/login-screen-page.html';
import SearchPage from '../public/pages/search-page.html';

const routes = [
   {
      path: '/',
      component: AboutPage,
   },
   {
      path: '/about/',
      component: AboutPage,
   },
   {
      path: '/login/',
      component: LoginScreenPage,
   },
   {
      path: '/logout/',
      async: function({resolve}) {
         const auth = getAuth();
         signOut(auth).finally(() => resolve({component: LoginScreenPage}));
      },
   },
   {
      path: '/search/',
      component: SearchPage,
      options: {
         force: true,
      },
   },
   // Default route (404 page). MUST BE THE LAST
   {
      path: '(.*)',
      url: './pages/404.html',
   },
];

export default routes;
